import numbro from "numbro";
import Web3 from "web3";
import BN from "bignumber.js";

const fmtOptions = { groupSeparator: "", fractionGroupSeparator: "" };

numbro.setDefaults({
  thousandSeparated: true,
});

/*
const {
  utils: { toBN },
} = Web3;
*/

// like fromWei
export const fromSmallestDenomination = (amount, token) => {
  const decimals = token.decimals || 18;
  const bn = BN(amount).div(BN(10).pow(BN(decimals)));
  return bn;
};

// like toWei
export const toSmallestDenomination = (amount, token) => {
  const decimals = token.decimals || 18;
  const bn = BN(amount).times(BN(10).pow(BN(decimals)));
  console.log(`${bn}`);
  return bn.toFormat(fmtOptions);
};

export const formatTokenAmount = (amount, token) => {
  const bn = fromSmallestDenomination(amount, token);
  return bn.toFormat({
    decimalSeparator: ".",
    groupSeparator: " ",
    groupSize: 3,
    fractionGroupSeparator: "",
  });
};

export const networks = [
  { id: 3, networkName: "mainnet" },
  { id: 1, networkName: "shasta" },
];

export const zeroAddress = "410000000000000000000000000000000000000000";
