import React, { useState } from "react";
import { useToast, Box, Button, Icon, Text, Tooltip } from "@chakra-ui/core";
import { useWeb3React } from "@web3-react/core";
import getExchangeContract from "../hooks/useExchangeContract";
import useTokenContract from "../hooks/useTokenContract";
import { formatTokenAmount } from "../utils";
import { Link } from "react-router-dom";
import bn from "bignumber.js";

import Web3 from "web3";

const swapTypes = {
  Buy: "0",
  Sell: "1",
};

const {
  utils: { fromWei },
} = Web3;

const fromSun = (n) => formatTokenAmount(n, { decimals: 6 });

const CurrencySymbol = ({ symbol }) => <Text display="inline"> {symbol} </Text>;

// strip trailing zeroes
const formatPrice = (n) => n.toFixed(6).replace(/0+$/, "").replace(/\.$/, "");

const PriceInfo = ({ price, token }) => {
  return (
    <Box fontSize="xs" color="gray.600">
      ~{formatPrice(price)} TRX/{token.symbol}
    </Box>
  );
};

const useCancelSwap = (data) => {
  const toast = useToast();
  const [pendingCancel, setPendingCancel] = useState(false);
  let exchangeContract;
  (async () => {
    exchangeContract = await getExchangeContract();
  })();

  const onClickCancel = async () => {
    try {
      setPendingCancel(true);
      await exchangeContract.methods.cancelSwapEntry(data.id).send();
      setPendingCancel(false);
      toast({
        title: "Swap canceled",
        description: "Locked funds have been returned to your address",
        status: "success",
      });
    } catch (err) {
      setPendingCancel(false);
      console.error(err);
      toast({
        title: err.name,
        description: err.message,
        status: "error",
      });
    }
  };
  return {
    onClickCancel,
    pendingCancel,
  };
};

const FulfillBuySwapButton = ({ data }) => {
  const toast = useToast();
  const web3react = useWeb3React();
  const [pendingFulfill, setPendingFulfill] = useState(false);
  const [pendingApprove, setPendingApprove] = useState(false);

  const isPending = pendingFulfill || pendingApprove;

  const getTokenContract = useTokenContract(data.token);

  const onClick = async () => {
    try {
      let exchangeContract = await getExchangeContract();

      //const exchangeContract = useExchangeContract();
      const exchangeContractAddress = exchangeContract.address;
      const tokenContract = await getTokenContract();
      // TODO: only wait for receipt after approve?
      // TODO: only approve if not already approved
      setPendingApprove(true);
      const allowance = await tokenContract.methods
        .allowance(window.tronWeb.defaultAddress.hex, exchangeContractAddress)
        .call();
      if (allowance < data.outputAmount) {
        await tokenContract.methods
          .approve(exchangeContractAddress, data.outputAmount)
          .send();
      }
      setPendingApprove(false);
      setPendingFulfill(true);
      const res = await exchangeContract.methods
        .fulfillBuySwap(data.id)
        .send({ shouldPollResponse: true });

      const fmtRes = res ? "Success" : "failed";
      toast({
        title: "Swap executed",
        description: `Result: ${fmtRes}`,
        status: "success",
      });
    } catch (err) {
      setPendingApprove(false);
      setPendingFulfill(false);
      console.error(err);
      toast({
        title: err.name,
        description: `${err.error} ${err.message}`,
        status: "error",
      });
    }
  };

  let loadingText = "";
  if (pendingApprove) {
    loadingText = "Approving token...";
  }
  if (pendingFulfill) {
    loadingText = "Executing swap...";
  }
  // https://github.com/chakra-ui/chakra-ui/issues/576
  const style = {
    whiteSpace: "normal",
    wordWrap: "break-word",
  };
  const tooltipLabel = `Send ${formatTokenAmount(
    data.outputAmount,
    data.token
  )} ${data.token.symbol} and receive ${fromSun(data.inputAmount)} TRX`;
  return (
    <Tooltip hasArrow label={tooltipLabel} placement="top">
      <Button
        isLoading={isPending}
        loadingText={loadingText}
        onClick={onClick}
        variant="outline"
        variantColor="blue"
        w={"100%"}
        style={style}
      >
        Sell {data.token.symbol}
      </Button>
    </Tooltip>
  );
};

const FulfillSellSwapButton = ({ data }) => {
  const toast = useToast();
  const [pendingFulfill, setPendingFulfill] = useState(false);

  let exchangeContract;
  (async () => {
    exchangeContract = await getExchangeContract();
  })();

  const onClick = async () => {
    try {
      setPendingFulfill(true);
      const sendOpts = {
        callValue: `${data.outputAmount}`,
        shouldPollResponse: true,
      };
      console.log({ sendOpts });
      const res = await exchangeContract.methods
        .fulfillSellSwap(data.id)
        .send(sendOpts);
      const fmtRes = res ? "Success" : "failed";

      toast({
        title: "Swap executed",
        description: `Result: ${fmtRes}`,
        status: "success",
      });
    } catch (err) {
      setPendingFulfill(false);
      console.error(err);
      toast({
        title: err.name,
        description: `${err.error} ${err.message}`,
        status: "error",
      });
    }
  };

  // https://github.com/chakra-ui/chakra-ui/issues/576
  const style = {
    whiteSpace: "normal",
    wordWrap: "break-word",
  };
  const tooltipLabel = `Send ${fromSun(
    data.outputAmount
  )} TRX and receive ${formatTokenAmount(data.inputAmount, data.token)} ${
    data.token.symbol
  }`;
  return (
    <Tooltip hasArrow label={tooltipLabel} placement="top">
      <Button
        isLoading={pendingFulfill}
        loadingText="Executing swap..."
        onClick={onClick}
        variant="outline"
        variantColor="blue"
        w={"100%"}
        style={style}
      >
        Buy {data.token.symbol}
      </Button>
    </Tooltip>
  );
};

export default ({ data }) => {
  const { pendingCancel, onClickCancel } = useCancelSwap(data);

  const web3react = useWeb3React();

  const renderCancelButton = () => {
    return (
      <Button
        isLoading={pendingCancel}
        loadingText="Cancel in progress"
        variant="outline"
        variantColor="red"
        w={"100%"}
        onClick={onClickCancel}
      >
        Cancel
      </Button>
    );
  };

  const renderButton = () => {
    console.log({ data });
    if (data.canceled) {
      return (
        <Button w={"100%"} variant="outline" disabled>
          ❌ Canceled
        </Button>
      );
    }
    if (data.fulfilled) {
      return (
        <Button w={"100%"} variant="outline" disabled>
          ✅ Fullfilled
        </Button>
      );
    }

    if (window.tronWeb.defaultAddress.hex === data.maker) {
      return renderCancelButton();
    } else {
      if (`${data.swapType}` === swapTypes.Buy) {
        return <FulfillBuySwapButton data={data} />;
      } else {
        return <FulfillSellSwapButton data={data} />;
      }
    }
  };

  const renderInfo = () => {
    if (`${data.swapType}` === swapTypes.Buy) {
      return (
        <>
          {formatTokenAmount(data.outputAmount, data.token)}{" "}
          <CurrencySymbol symbol={data.token.symbol} />{" "}
          <Icon name="arrow-forward" size="1.3em" color="gray.300" />{" "}
          {fromSun(data.inputAmount)}
          <CurrencySymbol symbol="TRX" />{" "}
          <PriceInfo price={data.price} token={data.token} />
        </>
      );
    } else {
      return (
        <>
          {fromSun(data.outputAmount)}
          <CurrencySymbol symbol="TRX" />{" "}
          <Icon name="arrow-forward" size="1.3em" color="gray.300" />{" "}
          {formatTokenAmount(data.inputAmount, data.token)}{" "}
          <CurrencySymbol symbol={data.token.symbol} />{" "}
          <PriceInfo price={data.price} token={data.token} />
        </>
      );
    }
  };

  // TODO: cancel button if mine
  return (
    <Box p={2} border="1px solid" borderRadius={2} borderColor="gray.300">
      <Box mb="0.5rem" textAlign="center">
        <Link to={`/otc/${data.id}`}>{renderInfo()}</Link>
      </Box>
      <Box>{renderButton()}</Box>
    </Box>
  );
};
