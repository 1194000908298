import React, { useState, useEffect } from "react";
import { Flex, Box, Skeleton, Heading, Text } from "@chakra-ui/core";
import exchangeAbi from "../abis/exchange.json";
import bn from "bignumber.js";

import SwapEntry from "./SwapEntry";
import ErrorBox from "./ErrorBox";
import getExchangeContract from "../hooks/useExchangeContract";
import useTokens from "../hooks/useTokens";
import { useWeb3React } from "@web3-react/core";
import { zeroAddress } from "../utils";
import promiseRetry from "promise-retry";
import SwapListControls from "./SwapListControls";

const swapTypes = {
  Buy: 0,
  Sell: 1,
};

const getPrice = (entry) => {
  let ethAmount;
  let tokenAmount;
  if (entry.swapType === swapTypes.Buy) {
    ethAmount = entry.inputAmount;
    tokenAmount = entry.outputAmount;
  } else {
    tokenAmount = entry.inputAmount;
    ethAmount = entry.outputAmount;
  }
  const pow = entry.token.decimals - 6;
  const price = bn(ethAmount.toString())
    .div(bn(tokenAmount.toString()))
    .times(bn(10).pow(pow));
  return price;
};

const augmentEntry = (tokens) => (entry, idx) => {
  const newEntry = {
    ...entry,
    token: getTokenByAddress(tokens, entry.erc20Token),
    id: idx,
  };
  const price = getPrice(newEntry);
  return { ...newEntry, price };
};

const EmptyState = () => (
  <Box textAlign="center" padding={4}>
    <Heading size="lg">There are no OTC trade requests at this time.</Heading>
    <Text>You can create the first one using the form below.</Text>
  </Box>
);

const getTokenByAddress = (tokens, address) => {
  return tokens.find((t) => t.contractAddress === address);
};

const defaultFilter = {
  sort: "entryId",
  Buy: true,
  Sell: true,
};

export default ({ filter: filter_ = defaultFilter } = {}) => {
  const [filter, setFilter] = useState(filter_);
  const [{ data, pending }, setResponse] = useState({ pending: true });
  const [error, setError] = useState(false);
  const tokens = useTokens();

  // const web3react = useWeb3React();
  //const { account } = web3react;
  const account = window.tronWeb.defaultAddress.hex;

  // console.log({ exchangeContract });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const loadData = async () => {
      try {
        const exchangeContract = await getExchangeContract();

        const arrayLength = await promiseRetry(async (retry) => {
          try {
            return (
              await exchangeContract.swapEntriesLength().call()
            ).toNumber();
          } catch (err) {
            return retry(err);
          }
        });
        const entries = await Promise.all(
          Array.from(Array(arrayLength).keys()).map(async (entryId) => {
            const entry = await promiseRetry(async (retry, retryNum) => {
              try {
                return await exchangeContract.swapEntries(entryId).call();
              } catch (err) {
                console.error(`entryId ${entryId}, retry #${retryNum}`, {
                  err,
                });
                return retry(err);
              }
            });
            return entry;
          })
        );

        //console.log(entries.map(e => e.outputAmount.toString()))
        const augmentedData = entries.map(augmentEntry(tokens));

        console.log({ entries, augmentedData });

        setResponse({ data: augmentedData });
      } catch (err) {
        // ignore timeout error...
        if (typeof err === "string" && err.match(/timeout/)) {
          return;
        }
        setError(err);
      }
    };

    setResponse({ pending: true });
    loadData();
    const timer = setInterval(() => {
      loadData();
    }, 3000);
    return () => clearInterval(timer);
  }, [/*web3react.chainId*/ 1]);

  const renderSwapControls = () => {
    if (filter.swapEntryId) {
      return;
    }
    return <SwapListControls setFilter={setFilter} filter={filter} />;
  };

  if (!data && error) {
    // todo: retry={retry}
    return <ErrorBox error={error} />;
  }
  if (pending) {
    return (
      <>
        {renderSwapControls()}
        <Skeleton h={10} my={2} />
      </>
    );
  }
  // hide non fullfillable or restricted
  let filteredEntries = data.filter((entry) => {
    if (filter.swapEntryId) {
      return entry.id === filter.swapEntryId;
    }
    // default to fulfillable
    const isFulFillable = !entry.canceled && !entry.fulfilled;
    const isAuthorizedToFulfill = [zeroAddress, account].includes(
      entry.restrictToAddress
    );
    const isMine = entry.maker === account;
    return isFulFillable && (isMine || isAuthorizedToFulfill);
  });

  // filter out Buy / Sell
  // if swapEntryId is set, ignore other filter rules
  if (!filter.swapEntryId) {
    filteredEntries = filteredEntries.filter((entry) => {
      if (entry.swapType === swapTypes.Buy) {
        return filter.Sell;
      }
      if (entry.swapType === swapTypes.Sell) {
        return filter.Buy;
      }
    });

    if (filter.sort) {
      // TODO...
      // filteredEntries = filteredEntries.sort()
      const direction = filter.sort[0];
      const key = filter.sort.substr(1);
      filteredEntries.sort((a, b) => {
        let res = a[key] - b[key];
        if (direction === "-") {
          return res * -1;
        }
        return res;
      });
    }
  }

  if (!filteredEntries.length) {
    return <EmptyState />;
  }
  return (
    <Box mb={-4}>
      {renderSwapControls()}
      <Flex wrap="wrap" mr={-4}>
        {filteredEntries.map((entry) => (
          <Box key={entry.id} w={{ base: 1 / 2, md: 1 / 3, lg: 1 / 5 }}>
            <Box mr={4} mb={4}>
              <SwapEntry data={entry} />
            </Box>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};
