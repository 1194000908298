import React, { useEffect } from "react";
import {
  useToast,
  Box,
  Heading,
  Flex,
  // Text,
  Button,
  // useTheme,
  Badge,
  Image,
} from "@chakra-ui/core";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { networks } from "../utils";
import { injectedConnector as connector } from "../connectors";

/*
const MenuItems = ({ children }) => (
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
    {children}
  </Text>
);
*/

const Header = (props) => {
  // const [show, setShow] = React.useState(false);
  // const handleToggle = () => setShow(!show);
  const { tronWeb, setTronWeb, account, setAccount } = props;

  const web3React = useWeb3React();
  // const theme = useTheme();
  const toast = useToast();

  const { error } = web3React;
  const network = networks[0];
  //  networks.find((c) => c.id === web3React.chainId) || networks[1];

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const timer = setInterval(() => {
      setTronWeb(window.tronWeb);
      setAccount(window.tronWeb.defaultAddress.base58);
    }, 200);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!error) return;
    console.error(error);
    toast({ title: error.name, description: error.message, status: "error" });
  }, [error, toast]);

  const renderWalletInfo = () => {
    //if (window.tronWeb) {

    //}
    //const onClick = () => {
    //web3React.activate(connector);
    //};
    const onClick = (setTronWeb) => () => {
      if (window.tronWeb) {
        console.log(window.tronWeb);
        setTronWeb(window.tronWeb);
      }
    };

    if (account) {
      return (
        <Button
          _hover={{}}
          _active={{}}
          _focus={{}}
          cursor="default"
          size="md"
          color="blue"
          fontFamily="monospace"
        >
          {`${account}`}
        </Button>
      );
    } else {
      return (
        <Button variantColor="blue" onClick={onClick(setTronWeb)}>
          Connect TronLink
        </Button>
      );
    }
  };

  const renderNetworkInfo = () => {
    if (network.networkName === "mainnet") return;
    return (
      <Box>
        <Badge variantColor="yellow">{network.networkName}</Badge>
      </Box>
    );
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1rem"
      // bg="teal.500"
      // color="white"
      borderBottom="1px solid"
      borderBottomColor="gray.100"
      {...props}
    >
      <Flex align="center" mr={5}>
        <Heading as="h1" size="lg">
          <Link to="/">
            <Image
              src="logo.svg"
              display="inline"
              alt="Oikos logo"
              htmlWidth="160px"
            />{" "}
            OTC <Badge>beta</Badge>
          </Link>
        </Heading>
      </Flex>

      {/*
      <Box
        display={{ sm: "block", md: "none" }}
        cursor="pointer"
        onClick={handleToggle}
      >
        <svg
          fill={theme.colors.blue[900]}
          width="12px"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>
      */}

      {/*
      <Box
        display={{ sm: show ? "block" : "none", md: "flex" }}
        width={{ sm: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
      >
        <MenuItems>
          <a href="https://github.com/oiko-cash/otc" target="_blank">
            GitHub
          </a>
        </MenuItems>
      </Box>
        */}

      {renderNetworkInfo()}
      <Box>{renderWalletInfo()}</Box>
    </Flex>
  );
};

export default Header;
