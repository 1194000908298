import config from "../config";
// import { useWeb3React } from "@web3-react/core";

const useTokens = () => {
  // const web3react = useWeb3React();
  const tokens = config.networks.mainnet.knownTokens;
  return tokens;
};

export default useTokens;
