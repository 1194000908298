import Web3 from "web3";
import erc20Abi from "../abis/erc20.json";
import { useWeb3React } from "@web3-react/core";

const useTokenContract = (token) => {
  const contractAddress = token.contractAddress;
  //const web3react = useWeb3React();
  // const web3 = web3react.library;
  // TODO: dont hardcode address
  // TODO: use righ network
  // console.log("web3react", web3react);
  // TODO: default to mainnet
  // TODO: dont hardcode the default api URL..
  //const web3 =
  //  web3react.library || new Web3("https://api.infura.io/v1/jsonrpc/ropsten");
  // console.log("web3", web3react);

  /*const contract = new web3.eth.Contract(
    erc20Abi,
    // TODO: use righ network
    contractAddress,
    { from: web3react.account }
  );*/
  const getTokenContract = async () =>
    await window.tronWeb.contract().at(contractAddress);

  return getTokenContract;
};

export default useTokenContract;
