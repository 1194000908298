import contractAddresses from "@otc/solidity";

export default {
  networks: {
    /*ropsten: {
      contractAddresses: contractAddresses.ropsten,
      knownTokens: [
        // TODO: copy list from uniswap?
        {
          name: "TestToken",
          symbol: "TST",
          contractAddress: "4110d23c1a26c0fa9276fcc6ede2f9febb08dedbbd",
          decimals: 18,
        },
        {
          name: "Yeenus",
          symbol: "YEENUS",
          // https://github.com/bokkypoobah/WeenusTokenFaucet
          contractAddress: "0xF6fF95D53E08c9660dC7820fD5A775484f77183A",
          decimals: 8,
        },
      ],
    },*/
    mainnet: {
      contractAddresses: {
        Exchange: "41712e3cabce8eed732b29a7123428e277a05454da",
      },
      knownTokens: [
        // TODO: copy list from uniswap?
        {
          name: "Oikos Network Token",
          symbol: "OKS",
          contractAddress: "41e11cdc164a9d8c1ae19d95b0165278690d39d84b",
          decimals: 18,
        },
        {
          name: "Synth TRX",
          symbol: "sTRX",
          contractAddress: "41a099cc498284ed6e25f3c99e6d55074e6ba42911",
          decimals: 18,
        },
        {
          name: "Synth sUSD",
          symbol: "sUSD",
          contractAddress: "41da2853b2bede0e3018f56d47624a413b2abe0831",
          decimals: 18,
        },
        {
          name: "Tether USD",
          symbol: "USDT",
          contractAddress: "41a614f803b6fd780986a42c78ec9c7f77e6ded13c",
          decimals: 6,
        },
      ],
    },
  },
};
