import React, { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Skeleton,
  Heading,
  Text,
  Select,
  Checkbox,
} from "@chakra-ui/core";
// import exchangeAbi from "../abis/exchange.json";
//
// import SwapEntry from "./SwapEntry";
// import ErrorBox from "./ErrorBox";
// import getExchangeContract from "../hooks/useExchangeContract";
// import useTokens from "../hooks/useTokens";

const OrderBy = ({ setFilter, filter }) => {
  const onChange = (e) => {
    console.log(e.target.value);
    setFilter({
      ...filter,
      sort: e.target.value,
    });
  };
  return (
    <Select onChange={onChange} placeholder="Sort by..." size="sm" maxW="200px">
      <option value="-price">Price descending</option>
      <option value="+price">Price ascending</option>
    </Select>
  );
};

export default ({ filter, setFilter } = {}) => {
  console.log({ filter });
  const onChange = (type) => (e) => {
    setFilter({
      ...filter,
      [type]: e.target.checked,
    });
  };
  return (
    <Box
      d="flex"
      padding={4}
      mb={4}
      backgroundColor="gray.600"
      borderRadius={2}
    >
      <OrderBy filter={filter} setFilter={setFilter} />
      <Checkbox
        color="white"
        ml={4}
        onChange={onChange("Buy")}
        isChecked={filter.Buy}
      >
        Buy
      </Checkbox>
      <Checkbox
        color="white"
        ml={4}
        onChange={onChange("Sell")}
        isChecked={filter.Sell}
      >
        Sell
      </Checkbox>
    </Box>
  );
};
