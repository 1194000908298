import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Grid, Box } from "@chakra-ui/core";

const LoginWarning = () => <Box p={4}>Please login with Tronlink.</Box>;
export default ({ children }) => {
  const [tronWeb, setTronWeb] = useState(false);
  const [account, setAccount] = useState(false);
  return (
    <Grid
      minH={"100%"}
      gridTemplateRows={"auto 1fr auto"}
      gridTemplateColumns={"100%"}
    >
      <Header
        tronWeb={tronWeb}
        setTronWeb={setTronWeb}
        setAccount={setAccount}
        account={account}
      />
      {window.tronWeb ? <main>{children}</main> : <LoginWarning />}
      <Footer />
    </Grid>
  );
};
